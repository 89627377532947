import { IDpsAuctionDonor } from "./DpsAuction";

export const GOLD_DONORS: IDpsAuctionDonor[] = [
  {
    name: 'Grand Bay Hotel SF',
    link: 'https://grandbayhotelsf.com/',
    imgSrc: 'grandbay.webp'
  }, {
    name: 'Kristina Hagan',
    link: 'https://www.kristinahaganclosetvalet.com/',
    imgSrc: 'kristina-hagan.png'
  }, {
    name: 'Diamond',
    link: 'https://diamondpetcompany.com/',
    imgSrc: 'diamond.webp'
  }, {
    name: 'Wishes and Wows',
    link: 'https://www.wishesandwows.com/',
    imgSrc: 'wishes-wows.avif'
  }
];


export const DONORS: IDpsAuctionDonor[] = [
  {
    name: 'US Ghost Adventures',
    link: 'https://usghostadventures.com/',
    imgSrc: 'usga.jpeg'
  }, {
    name: 'Malibu Wine Hikes',
    link: 'https://www.malibuwinehikes.com/',
    imgSrc: 'malibu-wine.jpeg'
  }, {
    name: 'Hang Smart',
    link: 'https://hangsmart.com/',
    imgSrc: 'hang-smart.webp?bg=black'
  }, {
    name: 'The Huntington',
    link: 'https://huntington.org/',
    imgSrc: 'huntington.svg'
  }, {
    name: 'Aqui',
    link: 'https://www.aquicalmex.com/',
    imgSrc: 'aqui.png'
  }, {
    name: 'Winchester Mystery House',
    link: 'https://winchestermysteryhouse.com/',
    imgSrc: 'winchester.webp'
  }, {
    name: 'Santa Barbara Zoo',
    link: 'https://www.sbzoo.org/',
    imgSrc: 'sb-zoo.webp'
  }, {
    name: 'The Walt Disney Family Museum',
    link: 'https://www.waltdisney.org/',
    imgSrc: 'wdfm.svg'
  }, {
    name: 'Oakland Zoo',
    link: 'https://www.oaklandzoo.org/',
    imgSrc: 'oakland-zoo.svg'
  }, {
    name: 'The Escape Game',
    link: 'https://theescapegame.com/',
    imgSrc: 'escape-game.svg'
  }, {
    name: 'Dream Inn',
    link: 'https://www.dreaminnsantacruz.com/',
    imgSrc: 'dream-inn.png?bg=black'
  }, {
    name: 'Gourmet Gift Baskets',
    link: 'https://www.gourmetgiftbaskets.com/',
    imgSrc: 'gourmet-gift-baskets.webp'
  }, {
    name: '100% Pure',
    link: 'https://www.100percentpure.com/index.html',
    imgSrc: '100percent-pure.jpg'
  }, {
    name: 'Dolphin Bay',
    link: 'https://www.thedolphinbay.com/',
    imgSrc: 'dolphin-bay.pdf'
  }, {
    name: 'Wushu Central',
    link: 'https://wushucentral.com/',
    imgSrc: 'wushu-central.png'
  }, {
    name: 'Wilderdog',
    link: 'https://www.wilderdog.com/',
    imgSrc: 'wilderdog.webp'
  }, {
    name: 'Children\'s Musem of Sonoma County',
    link: 'https://www.cmosc.org/',
    imgSrc: 'cmosc.png?bg=black'
  }, {
    name: 'Concannon Vineyard',
    link: 'https://www.concannonvineyard.com/',
    imgSrc: 'concannon.svg'
  }, {
    name: 'Erik\'s Delicafé',
    link: 'https://eriksdelicafe.com/',
    imgSrc: 'eriks.png'
  }, {
    name: 'Fieldwork Brewing',
    link: 'https://fieldworkbrewing.com/',
    imgSrc: 'fieldwork.svg'
  }, {
    name: 'House Family Vineyards',
    link: 'https://www.housefamilyvineyards.com/',
    imgSrc: 'housefamily.png?bg=black'
  }, {
    name: 'Nylabone',
    link: 'https://www.nylabone.com/about-us/nylabone-cares',
    imgSrc: 'nylabone.png'
  }, {
    name: 'Barry\'s Bootcamp Palo Alto',
    link: 'https://www.barrys.com/studio/palo-alto/',
    imgSrc: 'barrys.svg?bg=black'
  }, {
    name: 'Charlee Bear',
    link: 'https://charleebear.com/',
    imgSrc: 'charlee-bear.jpg'
  }, {
    name: 'Michael R. Burke',
    link: 'https://michaelrburke.com/',
    imgSrc: 'michael-burke.png'
  }, {
    name: 'Turtle Bay Exploration Park',
    link: 'https://www.turtlebay.org/',
    imgSrc: 'turtle-bay.png'
  }, {
    name: 'Ocean Honda of Burlingame',
    link: 'https://www.oceanhondaburlingame.com/',
    imgSrc: 'ocean-honda.jpg'
  }, {
    name: 'American Conservatory Theater',
    link: 'https://www.act-sf.org/',
    imgSrc: 'act.jpg'
  }, {
    name: 'Wine & Design',
    link: 'https://www.wineanddesign.com/losaltos-ca/',
    imgSrc: 'wine-and-design.png?bg=black'
  }, {
    name: 'Allele Tax Advisory',
    link: 'https://www.alleletax.com/',
    imgSrc: 'allele-tax.png'
  }, {
    name: 'Aloft San Francisco Airport',
    link: 'https://www.marriott.com/en-us/hotels/sfoal-aloft-san-francisco-airport/overview/',
    imgSrc: 'aloft.avif'
  }, {
    name: 'ArtCircle Studio',
    link: 'https://www1.artcircle.studio/',
    imgSrc: 'artcircle.jpg'
  }, {
    name: 'Beat The Bomb',
    link: 'https://www.beatthebomb.com/',
    imgSrc: 'beat-the-bomb.svg?bg=black'
  }, {
    name: 'Crowne Plaza Foster City',
    link: 'https://www.crowneplaza.com/hotels/us/en/foster-city/sfofc/hoteldetail',
    imgSrc: 'crowne-plaza.avif'
  }, {
    name: 'Total Wine & More',
    link: 'https://www.totalwine.com/',
    imgSrc: 'total-wine.png'
  }, {
    name: 'The Haunt',
    link: 'https://thehauntghosttours.com/',
    imgSrc: 'the-haunt.webp?bg=black'
  }, {
    name: 'Joy Steuerwald',
    link: 'joystewy.com',
    imgSrc: 'joy-stewy.png'
  }, {
    name: 'Rocko\'s Ice Cream Tacos',
    link: 'https://www.rockosicecreamtacos.com/',
    imgSrc: 'rockos.webp'
  }, {
    name: 'West Wind Drive-In',
    link: 'https://www.westwinddi.com/',
    imgSrc: 'west-wind.png'
  }, {
    name: 'GasKnight',
    link: 'https://gasknight.com/',
    imgSrc: 'gas-knight.avif?bg=black'
  }, {
    name: 'Topgolf San Jose',
    link: 'https://topgolf.com/us/san-jose/',
    imgSrc: 'topgolf.png'
  }, {
    name: 'Laura Zarrin',
    link: 'https://www.etsy.com/se-en/shop/LauraZarrin',
    imgSrc: 'laura-zarrin.webp'
  }, {
    name: 'AC Hotel Santa Clara',
    link: 'https://www.marriott.com/en-us/hotels/sjccl-ac-hotel-san-jose-santa-clara/overview/',
    imgSrc: 'ac-hotels.webp'
  }, {
    name: 'AC Hotel Sunnyvale',
    link: 'https://www.marriott.com/en-us/hotels/sjcan-ac-hotel-sunnyvale-cupertino/overview/',
    imgSrc: 'ac-hotels.webp'
  }, {
    name: 'Jaime Breeze',
    link: 'https://jaimebreeze.com/',
    imgSrc: 'jaime-breeze.png'
  }, {
    name: 'Theatre Works',
    link: 'https://theatreworks.org/',
    imgSrc: 'theatre-works.png?bg=black'
  }, {
    name: 'Thousand Skies',
    link: 'https://thousandskies.com/',
    imgSrc: 'thousand-skies.avif'
  }, {
    name: 'Westin San Francisco Airport',
    link: 'https://www.marriott.com/en-us/hotels/sfowi-the-westin-san-francisco-airport/overview/',
    imgSrc: 'westin.avif'
  }, {
    name: 'Alcatraz East',
    link: 'https://www.alcatrazeast.com/',
    imgSrc: 'alcatraz-east.png'
  }, {
    name: 'CuriOdyssey',
    link: 'https://curiodyssey.org/',
    imgSrc: 'curiodyssey.jpg'
  }, {
    name: 'San Jose Giants',
    link: 'https://www.milb.com/san-jose',
    imgSrc: 'sj-giants.svg'
  }, {
    name: 'Blackhawk Museum',
    link: 'https://blackhawkmuseum.org/',
    imgSrc: 'blackhawk.png?bg=black'
  }, {
    name: 'Peaceful Pathways',
    link: 'https://peacefulpathways.com/',
    imgSrc: 'peaceful-pathways.png'
  }, {
    name: 'Skunk Train',
    link: 'https://www.skunktrain.com/',
    imgSrc: 'skunk-train.png'
  }, {
    name: 'My Pet Peed',
    link: 'https://www.mypetpeed.com/',
    imgSrc: 'my-pet-peed.webp?bg=20b1d6'
  }, {
    name: 'USS Hornet Museum',
    link: 'https://uss-hornet.org/',
    imgSrc: 'hornet.png'
  }, {
    name: 'Steelers',
    link: 'https://www.steelers.com/',
    imgSrc: 'p-steelers.png'
  }, {
    name: 'Pet Food Express',
    link: 'https://www.petfood.express/',
    imgSrc: 'pfe.webp'
  }, {
    name: 'Farm To Pet',
    link: 'https://farmtopettreats.com/',
    imgSrc: 'farm-to-pet.avif'
  }, {
    name: 'Sibby\'s Cupcakery',
    link: 'https://sibbyscupcakery.com/',
    imgSrc: 'sibbys.svg'
  }, {
    name: 'Good Vibes Only',
    link: 'https://www.poolsidevacationrentals.com/vacation-rental-home.asp?PageDataID=193054',
    imgSrc: 'good-vibes.webp'
  }, {
    name: 'Sports Basement',
    link: 'https://shop.sportsbasement.com/',
    imgSrc: 'sports-basement.svg'
  }, {
    name: 'The Plex',
    link: 'https://www.gotoplex.com/',
    imgSrc: 'the-plex.png'
  }, {
    name: 'Nuance Jewelers',
    link: 'http://nuancejewelers.com/',
    imgSrc: 'nuance.png'
  }, {
    name: 'Original Grain',
    link: 'https://www.originalgrain.com/',
    imgSrc: 'original-grain.webp'
  }, {
    name: 'The Pawington',
    link: 'https://thepawington.com/',
    imgSrc: 'pawington.png'
  }, {
    name: 'Wondercide',
    link: 'https://www.wondercide.com/',
    imgSrc: 'wondercide.avif'
  }, {
    name: 'The Lawrence Hall of Science',
    link: 'https://lawrencehallofscience.org/',
    imgSrc: 'lawrence.png?bg=black'
  }, {
    name: 'San Jose Brew Bike',
    link: 'https://www.sanjosebrewbike.com/',
    imgSrc: 'sj-brew-bike.png'
  }/*
  , {
    name: '',
    link: '',
    imgSrc: ''
  }
  */
];
